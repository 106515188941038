<template>
  <button
    :class="[
      'flex items-center justify-center whitespace-nowrap text-lg normal-case font-bold rounded-md h-19 px-8',
      'outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2',
      {
        'bg-blue text-white hover:bg-blue-dark focus:bg-blue':
          type === $options.ButtonTypes.Primary,
        'text-black-light border-2 border-black-light bg-white hover:text-gray hover:border-gray':
          type === $options.ButtonTypes.Secondary,
        'cursor-not-allowed opacity-50': disabled,
      },
    ]"
    :type="nativeType"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <SvgIcon v-if="icon" class="ucrs-icon--button" :name="icon" />

    <div :class="{ 'hidden md:flex': iconMobile }">
      <slot></slot>
    </div>

    <slot name="right"></slot>
  </button>
</template>

<script>
const Primary = 'primary'
const Secondary = 'secondary'
const ButtonTypes = { Primary, Secondary }

export default {
  props: {
    type: {
      type: String,
      default: ButtonTypes.Primary,
    },

    nativeType: {
      type: String,
      default: 'button',
    },

    icon: {
      type: String,
      default: '',
    },

    iconMobile: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  ButtonTypes,
}
</script>
