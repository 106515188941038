export default {
  name: 'UcrsSlotComponent',
  props: {
    component: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    clickAction: {
      type: Function,
      required: true,
    },
    keydownAction: {
      type: Function,
      required: true,
    },
  },
  render(h) {
    const slot = this.component.$scopedSlots.header()
    return h(
      'button',
      {
        attrs: {
          role: 'tab',
          'aria-selected': `${this.isActive}`,
          tabindex: this.isActive ? 0 : -1,
        },
        class: {
          'ucrs-tabs__button': true,
          'ucrs-tabs__button--active': this.isActive,
        },
        on: {
          click: this.clickAction,
          keydown: this.keydownAction,
        },
      },
      slot
    )
  },
}
