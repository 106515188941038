var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'flex items-center justify-center whitespace-nowrap text-lg normal-case font-bold rounded-md h-19 px-8',
    'outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2',
    {
      'bg-blue text-white hover:bg-blue-dark focus:bg-blue':
        _vm.type === _vm.$options.ButtonTypes.Primary,
      'text-black-light border-2 border-black-light bg-white hover:text-gray hover:border-gray':
        _vm.type === _vm.$options.ButtonTypes.Secondary,
      'cursor-not-allowed opacity-50': _vm.disabled,
    },
  ],attrs:{"type":_vm.nativeType,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.icon)?_c('SvgIcon',{staticClass:"ucrs-icon--button",attrs:{"name":_vm.icon}}):_vm._e(),_vm._v(" "),_c('div',{class:{ 'hidden md:flex': _vm.iconMobile }},[_vm._t("default")],2),_vm._v(" "),_vm._t("right")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }