<template>
  <div
    ref="Swiper"
    :class="[
      'swiper',
      'ucrs-swiper-container',
      {
        'ucrs-swiper-container--overlay': overlay,
        'ucrs-swiper-container--overlay--vertical': overlayVertical,
        'ucrs-swiper-container--equal-heights': equalHeights,
        'ucrs-swiper-container--dots-outside': dotsPosition === 'outside',
        'ucrs-swiper-container--dots-inside': dotsPosition === 'inside',
        'ucrs-swiper-container--nav-aside': navPosition === 'aside',
        'ucrs-swiper-container--nav-aside-desktop':
          navPosition === 'aside-desktop',
        'ucrs-swiper-container--nav-bottomCenter':
          navPosition === 'bottom-center',
      },
    ]"
  >
    <div class="swiper-wrapper">
      <slot></slot>
    </div>

    <template v-if="navigation">
      <slot name="button-prev">
        <div
          class="ucrs-swiper-nav-button swiper-button-prev"
          :class="{
            'ucrs-swiper-nav-button--no-bg': navPosition === 'white-large',
          }"
        >
          <SvgIcon
            :name="
              navPosition === 'bottom-center' || navPosition === 'white-large'
                ? 'angle-left-white'
                : 'angle-left-blue'
            "
            class="ucrs-arrow"
            :class="{
              ' ucrs-arrow--tiny': navPosition !== 'white-large',
            }"
          />
        </div>
      </slot>
      <slot name="button-next">
        <div
          class="ucrs-swiper-nav-button swiper-button-next"
          :class="{
            'ucrs-swiper-nav-button--no-bg': navPosition === 'white-large',
          }"
        >
          <SvgIcon
            :name="
              navPosition === 'bottom-center' || navPosition === 'white-large'
                ? 'angle-right-white'
                : 'angle-right-blue'
            "
            class="ucrs-arrow"
            :class="{
              ' ucrs-arrow--tiny': navPosition !== 'white-large',
            }"
          />
        </div>
      </slot>
    </template>
    <div v-if="pagination" class="swiper-pagination"></div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },

    rootClass: {
      type: String,
      default: '',
    },

    overlay: {
      type: Boolean,
      default: false,
    },

    overlayVertical: {
      type: Boolean,
      default: false,
    },

    navigation: {
      type: Boolean,
      default: false,
    },

    navPosition: {
      type: String,
      default: 'aside-desktop',
    },

    pagination: {
      type: Boolean,
      default: false,
    },

    dotsPosition: {
      type: String,
      default: 'inside',
    },

    virtual: {
      type: Boolean,
      default: false,
    },

    equalHeights: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      swiper: null,
    }
  },
  mounted() {
    this.swiper = new this.$swiper(this.$refs.Swiper, this.computeOptions())
  },

  methods: {
    computeOptions() {
      const { options } = this

      if (!options) {
        return
      }

      const { pagination, navigation, virtual } = this

      const events = {
        on: {
          slideChangeTransitionStart: (swiper) => {
            this.$emit('slide-index', swiper.activeIndex)
          },
          init: () => {
            this.$emit('init')
          },
        },
      }

      const modules = [
        this.$swiperModules.Keyboard,
        ...(pagination ? [this.$swiperModules.Pagination] : []),
        ...(navigation ? [this.$swiperModules.Navigation] : []),
        ...(virtual ? [this.$swiperModules.Virtual] : []),
      ]

      return {
        keyboard: true,
        modules,
        ...options,
        ...events,
        ...(pagination
          ? {
              pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
                clickable: true,
              },
            }
          : {}),
        ...(navigation
          ? {
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            }
          : {}),
        ...(virtual ? { virtual: true } : {}),
      }
    },
    slideTo(n) {
      this.swiper.slideTo(n)
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/swiper';
</style>
