<template>
  <div class="ucrs-tipsCard">
    <NuxtLink :to="`${item.link.href}`" aria-hidden="true" tabindex="-1">
      <div
        class="relative bg-cover bg-no-repeat bg-center rounded"
        :class="`ucrs-tipsCard__img--${size}`"
        :style="style"
      >
        <p class="absolute bottom-0 p-5 text-3xl font-bold text-white">
          {{ item.name }}
        </p>
      </div>
    </NuxtLink>
    <div class="flex flex-col flex-1 pt-10 px-5">
      <p class="text-base font-bold mb-4">
        <NuxtLink :to="`${item.link.href}`" aria-hidden="true" tabindex="-1">
          {{ item.title }}
        </NuxtLink>
      </p>
      <p v-if="item.subtitle" v-dompurify-html="item.subtitle"></p>

      <div class="flex items-center mt-12 mb-2">
        <NuxtLink
          class="text-sm text-blue font-bold uppercase focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue"
          :to="`${item.link.href}`"
          :name="`&lid=${item.name}|homepage`"
          :aria-label="computedAriaLabel"
        >
          {{ item.link.text }}
        </NuxtLink>
        <SvgIcon name="angle-right-blue" class="max-h-5 max-w-5 ml-6" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return null
      },
    },
    img: {
      type: String,
      default: '',
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    computedAriaLabel() {
      const { name, title, link } = this.item ?? {}
      return `${name}. ${title}. ${link?.text}`
    },
    style() {
      if (!this.img) return ''

      const image = this.$img(this.img)

      return this.gradient
        ? `background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.75)), url(${image})`
        : `background-image: url(${image})`
    },
  },
}
</script>

<style lang="postcss" scoped>
.ucrs-tipsCard {
  @apply flex flex-col h-full;
  &__img {
    &--medium {
      @apply h-100;
    }
  }
}
</style>
